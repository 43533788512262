import Box from "@mui/material/Box";
import Blockquote from "components/Blockquote";
import DonateButton from "components/DonateButton";
import { Markdown } from "components/Markdown";

import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import img1 from "src/images/community/celebrate-our-wins.jpg";
import sig from "src/images/community/shari-sig.jpg";

export default function Page() {
  return (
    <BlogLayout slug="celebrate-our-wins">
      <Box component="img" src={img1} display="block" sx={{ width: "100%" }} />

      <Paragraph component="div">
        <Markdown>
          {`
Dear Community,

EOCP is an oasis in the middle of an area of homelessness, hurt, loneliness and despair. **For 33 years we have stayed rooted in Oakland, and specifically in East Oakland, responding to the housing crisis that many in our community are facing.** Last year, we have served 1,336 individuals that included 1,032 adults and 304 children (983 households).

The team at EOCP is using all their strength, knowledge and experience in making lives turn a corner for the better. It is important that we remain strong and sustain the services we offer to improve the lives of our clients that we see every day. **We are doing our best to reduce homelessness and create a healthier, more vibrant community**.

Your donation, no matter how small contributes to the sustainability of our organization and its many programs including:

-   Signature transitional housing that provides services for families. We are the only provider that keeps the family intact as we help overcome the obstacles needed to find permanent housing.
-   Our primary shelter known as Crossroads is sanctuary for over 100 adults and five families.
-   We are a respite provider for those without homes that need time to recover post exiting the hospital.
-   Our Veterans program is growing as we provide services, advocacy and housing for those who have served our country.
-   We also support those that are on probation/parole.

As EOCP continues to make difference in our community by responding to the housing crisis and supporting unhoused individuals and families obtain permanent housing **we need your help to amplify our impact**.
          `}
        </Markdown>
      </Paragraph>
      <Blockquote>
        Some days are a heavy lift as we may be short on staff, we have an
        unexpected repair on a family unit, or we face a multitude of unexpected
        crises. Other days, a family is accepted in our program and can have a
        roof over their head and a bed to sleep in, or someone who is discharged
        from the hospital, now has a place to lay, access to food and someone to
        remind them to take their medicine. The best days are when a family or
        an individual move into permanent housing.{" "}
        <b>
          Those are the days that the donors, supporters and community can help.
        </b>
      </Blockquote>
      <Paragraph component="div">
        <Markdown>{`During this holiday season of giving, remember EOCP, a local, community-based organization that is making a difference in East Oakland. We thank you for your support. **Celebrate our wins with us**.

Best wishes as we start our Holiday Season,`}</Markdown>
        <br />
        <img src={sig} width={200} />
        <br />
        <br />
        Shari P. Wooldridge
        <br />
        <i>Interim Executive Director</i>
      </Paragraph>
      <DonateButton />
    </BlogLayout>
  );
}
